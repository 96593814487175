import styles from "./Io.module.scss";

const Io = () => {
    const startExperienceDate = new Date("2019-01-01");
    const currentDate = new Date();

    const calculateYears = (startDate) => {
        const difference = currentDate - startDate;
        return Math.floor(difference / (365.25 * 24 * 60 * 60 * 1000));
    };

    const yearsOfExperience = calculateYears(startExperienceDate);

    return (
        <div className={styles.container}>
            {/* Immagini */}
            <div className={styles.imageWrapper}>
                <img src="cover.jpg" alt="Lago Marcio" />

                <div className={styles.profilePicWrapper}>
                    <img src="profile-pic.png" alt="Stefano Ciotola" />
                </div>
            </div>

            <div className={styles.bioContainer}>
                <div className={styles.bioTitle}>
                    <h3 className="fs-5 text-dark">Stefano Ciotola</h3>

                    <div className="d-flex flex-column text-dark">
                        <p className="text-dark" style={{ fontWeight: 460 }}>
                            Creative Web & 3D Developer
                        </p>
                        <p className="text-dark text-black-50" style={{ fontSize: 14, fontWeight: 450 }}>
                            Brescia, Italy
                        </p>
                    </div>
                </div>

                <div className={styles.descriptionContainer}>
                    {/* Descrizione */}
                    <p>
                        Sviluppatore front-end con {yearsOfExperience} anni di esperienza nella creazione di applicazioni web interattive.
                        <br />
                        Esperto in HTML, CSS e JavaScript, con una solida competenza in framework 3D come Three.js e BabylonJS.
                        <br />
                        Possiedo anche conoscenze di sviluppo back-end in ambito Node.js e PHP, specialmente con il framework Laravel.
                    </p>

                    {/* Skills */}
                    <div className={styles.skillsWrapper}>
                        <span className="text-muted">HTML</span>
                        <span className="text-muted">CSS</span>
                        <span className="text-muted">SCSS</span>
                        <span className="text-muted">SASS</span>
                        <span className="text-muted">JavaScript</span>
                        <span className="text-muted">Node.js</span>
                        <span className="text-muted">Express.js</span>
                        <span className="text-muted">React</span>
                        <span className="text-muted">Vite</span>
                        <span className="text-muted">Inertia.js</span>
                        <span className="text-muted">PHP</span>
                        <span className="text-muted">SmartyPHP</span>
                        <span className="text-muted">Laravel</span>
                        <span className="text-muted">SQL</span>
                        <span className="text-muted">Git</span>
                        <span className="text-muted">Prestashop</span>
                        <span className="text-muted">WordPress</span>
                        <span className="text-muted">Photoshop</span>
                        <span className="text-muted">Illustrator</span>
                        <span className="text-muted">InDesign</span>
                        <span className="text-muted">Adobe XD</span>
                        <span className="text-muted">Figma</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Io;
