import styles from "./Projects.module.scss";

import projectsData from "../../projects.json";

const Projects = () => {
    return (
        <main>
            {/* Elenco progetti */}
            <div>
                {/* Titolo */}
                <h2 className={`fs-5 text-dark mb-0 ${styles.title}`}>Progetti</h2>

                {/* Elenco */}
                <ul className={styles.cvList}>
                    {/* Card */}
                    {projectsData.projects.map((project, index) => (
                        <li className={styles.cvCard} key={`project-${index}`}>
                            <img alt={`Logo ${project.name}`} src={project.mediaLink} />

                            <div>
                                <div className={styles.jobTitle}>
                                    <h3 className="fs-6 mb-0">{project.name}</h3>
                                </div>

                                <ul className={styles.jobDescription}>
                                    {project.activities.map((activity, idx) => (
                                        <li key={idx}>
                                            <strong>{activity.name}:</strong> {activity.details}
                                        </li>
                                    ))}
                                </ul>

                                {project.technologies && project.technologies.length > 0 && (
                                    <p className={styles.technologies}>
                                        {project.technologies.map((technology, index) => (
                                            <span key={`technology-${index}`}>{technology}</span>
                                        ))}
                                    </p>
                                )}
                                {project.projectLink && project.projectLink.length > 0 && (
                                    <a className={styles.projectLink} href={project.projectLink} target="_blank" rel="noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w">
                                            <path
                                                fill="currentColor"
                                                d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zm11-3v8h-2V6.413l-7.793 7.794l-1.414-1.414L17.585 5H13V3z"
                                            ></path>
                                        </svg>
                                        Link al progetto
                                    </a>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </main>
    );
};

export default Projects;
