import styles from "./Info.module.scss";

const Info = () => {
    const startExperienceDate = new Date("2019-01-01");
    const currentDate = new Date();

    const calculateYears = (startDate) => {
        const difference = currentDate - startDate;
        return Math.floor(difference / (365.25 * 24 * 60 * 60 * 1000));
    };

    const yearsOfExperience = calculateYears(startExperienceDate);

    return (
        <div className={styles.container}>
            <h2 className="fs-5 text-dark mb-0">Informazioni</h2>

            <div className={styles.listContainer}>
                <ul>
                    <li>
                        <span>Luogo</span>
                        <span>Bergamo, Italy</span>
                    </li>
                    <li>
                        <span>Esperienza</span>
                        <span>{yearsOfExperience} anni</span>
                    </li>
                    <li>
                        <span>In cerca di</span>
                        <span>Opportunità di lavoro da remoto in ambito front-end e/o 3D</span>
                    </li>
                    {/*<li >
                        <span >
                            Relocation
                        </span>
                        <span >No</span>
                    </li>*/}
                </ul>
            </div>
        </div>
    );
};

export default Info;
