import styles from "./CurriculumVitae.module.scss";

const CurriculumVitae = () => {
    return (
        <main>
            {/* EXPERIENCE */}
            <div>
                {/* Titolo */}
                <h2 className={`fs-5 text-dark mb-0 ${styles.title}`}>Esperienza lavorativa</h2>

                <ul className={styles.cvList}>
                    {/* Card */}
                    <li className={styles.cvCard}>
                        <img alt="Logo Glacom" src="logo/glacom.jpg" />

                        <div>
                            <div className={styles.jobTitle}>
                                <h3 className="fs-6 mb-0">
                                    Creative Front-End 3D Developer
                                    <br />
                                    Responsabile reparto Front-End
                                </h3>
                                <span>Full-time</span>
                            </div>

                            <div className={styles.jobInfo}>
                                <div className={styles.jobName}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            fill="currentColor"
                                            d="M7 5V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zM4 16v3h16v-3zm0-2h16V7H4zM9 3v2h6V3zm2 8h2v2h-2z"
                                        ></path>
                                    </svg>
                                    <span>Glacom</span>
                                </div>

                                <div className={styles.jobLocation}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="m12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9zM12 13a2 2 0 1 1 0-4a2 2 0 0 1 0 4"></path>
                                    </svg>
                                    <span>Toscolano Maderno, BS</span>
                                </div>

                                <div className={styles.jobDate}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            fill="currentColor"
                                            d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1zm11 10H4v8h16zm-9 2v4H6v-4zM7 5H4v4h16V5h-3v2h-2V5H9v2H7z"
                                        ></path>
                                    </svg>
                                    <span>Luglio 2019 - Presente</span>
                                </div>
                            </div>

                            <p className={styles.jobDescription}>
                                <strong>2021 - Presente:</strong> Come naturale evoluzione del mio percorso professionale, ho assunto il ruolo di capo reparto Front-End, coordinando un team di giovani
                                sviluppatori e guidando la sezione 3D.
                                <br />
                                Ho gestito colloqui di assunzione e sviluppato processi per ottimizzare la produttività e la qualità del lavoro nel nostro dipartimento.
                                <br />
                                Le mie responsabilità includono:
                                <br />- Supervisionare e coordinare il team di front-end, assicurando il raggiungimento degli obiettivi e il mantenimento degli standard di qualità.
                                <br />- Implementare soluzioni front-end con tecnologie 3D come Three.js e Babylon.js, innovando l'offerta di prodotti digitali dell'azienda.
                                <br />- Gestire il processo di assunzione, identificando e reclutando nuovi talenti per supportare la crescita del team e dell'azienda.
                                <br />- Fungere da collegamento tra il team di front-end e altre aree aziendali, facilitando la comunicazione e l'integrazione tra i diversi reparti.
                                <br className="mt-2 d-block" />
                                <strong>2019 - 2021:</strong> Assunto come Sviluppatore Front-End Junior, ho maturato e perfezionato le mie competenze tecniche in HTML, CSS e JavaScript, contribuendo
                                a svariati progetti web, acquisendo una solida base professionale.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            {/* // EXPERIENCE */}

            {/* EDUCATION */}
            <div>
                {/* Titolo */}
                <h2 className={`fs-5 text-dark mb-0 ${styles.title}`}>Educazione</h2>

                <ul className={styles.cvList}>
                    <li className={styles.cvCard}>
                        <img alt="Logo Accademia di Belle Arti SantaGiulia" src="logo/hdemia.jpg" />

                        <div>
                            <div className={styles.jobTitle}>
                                <h3 className="fs-6 mb-0">Laurea triennale in Web e Comunicazione d'impresa</h3>
                            </div>

                            <div className={styles.jobInfo}>
                                <div className={styles.jobName}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="v.5">
                                        <path
                                            fill="currentColor"
                                            d="M4 11.333L0 9l12-7l12 7v8.5h-2v-7.333l-2 1.166v6.678l-.223.275A9.983 9.983 0 0 1 12 22a9.983 9.983 0 0 1-7.777-3.714L4 18.011zM6 12.5v4.792A7.979 7.979 0 0 0 12 20a7.978 7.978 0 0 0 6-2.708V12.5L12 16zM3.97 9L12 13.685L20.03 9L12 4.315z"
                                        ></path>
                                    </svg>
                                    <span class="yb zb" x-text="item.university">
                                        Accademia di Belle Arti SantaGiulia
                                    </span>
                                </div>

                                <div className={styles.jobLocation}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="v.5">
                                        <path fill="currentColor" d="m12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9zM12 13a2 2 0 1 1 0-4a2 2 0 0 1 0 4"></path>
                                    </svg>
                                    <span class="yb zb" x-text="item.location">
                                        Brescia, Italy
                                    </span>
                                </div>

                                <div className={styles.jobDate}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="v.5">
                                        <path
                                            fill="currentColor"
                                            d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1zm11 10H4v8h16zm-9 2v4H6v-4zM7 5H4v4h16V5h-3v2h-2V5H9v2H7z"
                                        ></path>
                                    </svg>
                                    <span class="yb zb" x-text="item.period">
                                        2016 - 2020
                                    </span>
                                </div>
                            </div>

                            <p className={styles.jobDescription}>
                                Nel corso della mia formazione triennale, conclusasi con il conseguimento della laurea con votazione di 110 e lode, ho acquisito una preparazione completa che spazia
                                dalle fondamenta teoriche della comunicazione d'impresa alle applicazioni pratiche nel campo del web. Il cuore del percorso didattico è stato lo studio approfondito del
                                web e della programmazione, un filo conduttore mantenuto per tutte e tre le annualità. Parallelamente ho sviluppato competenze specifiche in digital marketing,
                                imparando a padroneggiare le strategie online per massimizzare la visibilità aziendale attraverso SEO, SEA e SEM. Un'altra componente essenziale del corso è stata la
                                grafica: ho imparato a utilizzare il pacchetto Adobe Creative Cloud per la progettazione di elementi grafici e interfacce user-oriented.
                            </p>
                        </div>
                    </li>

                    <li className={styles.cvCard}>
                        <img alt="Logo IIS Decio Celeri" src="logo/decio-celeri.jpg" />

                        <div>
                            <div className={styles.jobTitle}>
                                <h3 className="fs-6 mb-0">Diploma di Maturità Scientifica</h3>
                            </div>

                            <div className={styles.jobInfo}>
                                <div className={styles.jobName}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="v.5">
                                        <path
                                            fill="currentColor"
                                            d="M4 11.333L0 9l12-7l12 7v8.5h-2v-7.333l-2 1.166v6.678l-.223.275A9.983 9.983 0 0 1 12 22a9.983 9.983 0 0 1-7.777-3.714L4 18.011zM6 12.5v4.792A7.979 7.979 0 0 0 12 20a7.978 7.978 0 0 0 6-2.708V12.5L12 16zM3.97 9L12 13.685L20.03 9L12 4.315z"
                                        ></path>
                                    </svg>
                                    <span class="yb zb" x-text="item.university">
                                        IIS Decio Celeri
                                    </span>
                                </div>

                                <div className={styles.jobLocation}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="v.5">
                                        <path fill="currentColor" d="m12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zm4.95-7.778a7 7 0 1 0-9.9 0L12 20.9zM12 13a2 2 0 1 1 0-4a2 2 0 0 1 0 4"></path>
                                    </svg>
                                    <span class="yb zb" x-text="item.location">
                                        Lovere, Bergamo
                                    </span>
                                </div>

                                <div className={styles.jobDate}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="v.5">
                                        <path
                                            fill="currentColor"
                                            d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1zm11 10H4v8h16zm-9 2v4H6v-4zM7 5H4v4h16V5h-3v2h-2V5H9v2H7z"
                                        ></path>
                                    </svg>
                                    <span class="yb zb" x-text="item.period">
                                        2009 - 2014
                                    </span>
                                </div>
                            </div>

                            <p className={styles.jobDescription}>
                                Durante il mio percorso liceale ho costruito una solida base nelle materie scientifiche e umanistiche, sviluppando un pensiero analitico e una forte propensione al
                                problem solving.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            {/*// EDUCATION */}
        </main>
    );
};

export default CurriculumVitae;
