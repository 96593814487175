import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Io from "./Components/Io/Io";
import About from "./Components/About/About";
import Info from "./Components/Info/Info";
import TabToggle from "./Components/TabToggle/TabToggle";

function App() {
    return (
        <section className="container-fluid noPad429">
            <div className="container">
                <div className="row">
                    <div className="col-12 grid">
                        {/* Prima colonna */}
                        <div className="firstColumn">
                            {/* Io */}
                            <Io />

                            {/* About me */}
                            <About />

                            {/* Info generali */}
                            <Info />
                        </div>

                        {/* */}
                        <div className="secondColumn">
                            <TabToggle />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default App;
