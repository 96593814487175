import styles from "./About.module.scss";

const About = () => {
    const birthDate = new Date("1995-12-15");
    const currentDate = new Date();

    const calculateYears = (startDate) => {
        const difference = currentDate - startDate;
        return Math.floor(difference / (365.25 * 24 * 60 * 60 * 1000));
    };

    const age = calculateYears(birthDate);

    return (
        <div className={styles.container}>
            <h2 className="fs-5 text-dark mb-0">About me</h2>

            <div className={styles.bioContainer}>
                <div className="a b ac prose-gray">
                    <p>
                        Mi chiamo Stefano, ho {age} anni e vengo da Lovere, uno dei borghi più belli d'Italia situato nella provincia di Bergamo. Sono appassionato di problem solving e amo
                        costantemente imparare cose nuove. Negli ultimi anni la mia curiosità mi ha portato a esplorare il mondo del 3D, dove ho approfondito le sue possibilità creative e tecniche.
                        <br />
                        Oltre alla mia passione per la tecnologia, sono un amante della natura e trascorro molto tempo all'aperto. Le passeggiate in montagna, in particolare, sono il mio modo
                        preferito di ricaricarmi e trovare nuova ispirazione.
                    </p>
                </div>

                <hr />

                <div className={`${styles.linkContainer} d-flex flex-column`}>
                    <a href="mailto:me@stefano.design" className="text-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w">
                            <path fill="currentColor" d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1m17 4.238l-7.928 7.1L4 7.216V19h16zM4.511 5l7.55 6.662L19.502 5z"></path>
                        </svg>
                        <span className="zb group-hover:hc group-hover:ic">me@stefano.design</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default About;
