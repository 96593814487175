import React, { useState, useEffect } from "react";
import styles from "./TabToggle.module.scss";
import CurriculumVitae from "../CurriculumVitae/CurriculumVitae";
import Projects from "../Projects/Projects";

function TabToggle() {
    const [selectedTab, setSelectedTab] = useState("cv");
    const [visibleContent, setVisibleContent] = useState("cv");

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisibleContent(selectedTab);
        }, 500); // Dura quanto l'animazione CSS

        return () => clearTimeout(timer);
    }, [selectedTab]);

    return (
        <div>
            <ul className={styles["tab-toggle-container"]} role="tablist">
                <li className={styles["tab-toggle-item"]} role="presentation">
                    <button
                        className={`${styles["tab-toggle-item-button"]} ${selectedTab === "cv" ? styles["tab-toggle-item-button__active"] : ""}`}
                        onClick={() => setSelectedTab("cv")}
                        type="button"
                        role="tab"
                        aria-selected={selectedTab === "cv"}
                    >
                        Curriculum Vitae
                    </button>
                </li>
                <li className={styles["tab-toggle-item"]} role="presentation">
                    <button
                        className={`${styles["tab-toggle-item-button"]} ${selectedTab === "projects" ? styles["tab-toggle-item-button__active"] : ""}`}
                        onClick={() => setSelectedTab("projects")}
                        type="button"
                        role="tab"
                        aria-selected={selectedTab === "projects"}
                    >
                        Progetti
                    </button>
                </li>
            </ul>

            <div className={`${styles["fade-transition"]} ${visibleContent === "cv" ? styles["visible"] : ""}`}>{selectedTab === "cv" && <CurriculumVitae />}</div>

            <div className={`${styles["fade-transition"]} ${visibleContent === "projects" ? styles["visible"] : ""}`}>{selectedTab === "projects" && <Projects />}</div>
        </div>
    );
}

export default TabToggle;
